<header>
  <div class="d-flex justify-content-between align-items-center" [style.display]="this.headerStyle">
    <div class="m-header d-flex align-items-center">
      <a class="mobile-menu" id="mobile-collapse1" href="javascript:" (click)="this.NavCollapsedMob.emit()"><span></span></a>
      <a [routerLink]="['/dashboard/home']" class="b-brand">
        <span class="b-title">
          <img src="../../../../../assets/images/logo.svg" alt="Logo" class="logo">
        </span>
      </a>
    </div>

    <div class="d-flex align-items-center">
      <ul class="navbar-nav ms-auto" style="padding-right: 24px;">
        <li>
          <div class="dropdown drp-user" ngbDropdown placement="auto">
            <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
              <i class="icon feather icon-settings"></i>
            </a>
            <div class="dropdown-menu dropdown-menu-end profile-notification" ngbDropdownMenu>
              <div class="pro-head">
                <span>{{ userName }}</span>
                <a href="javascript:" class="dud-logout" title="Logout" (click)="logout()">
                  <i class="feather icon-log-out"></i>
                </a>
              </div>
              <ul class="pro-body">
                <li>
                  <a href="javascript:" class="dropdown-item" (click)="goToSettings()"><i class="feather icon-settings"></i> Configurações</a>
                </li>
              </ul>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</header>

<div class="pc-menu-overlay" (click)="closeMenu()" (keydown)="handleKeyDown($event)" tabindex="0"></div>
