// angular import
import { Component, EventEmitter, Output } from '@angular/core';
import { Router, RouterModule } from '@angular/router';
import { CommonModule } from '@angular/common';

// project import
import { SharedModule } from 'src/app/theme/shared/shared.module';
import { NavRightComponent } from './nav-right/nav-right.component';
import { TranslateService } from '@ngx-translate/core';
import { AuthenticationService } from 'src/app/theme/shared/service/authentication.service';

@Component({
  selector: 'app-nav-bar',
  standalone: true,
  imports: [SharedModule, NavRightComponent, RouterModule, CommonModule, ],
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.scss']
})
export class NavBarComponent {
  // public props
  @Output() NavCollapsedMob = new EventEmitter();
  navCollapsedMob;
  headerStyle: string;
  menuClass: boolean;
  collapseStyle: string;
  userName: string = '';

  // constructor
  constructor(   private authenticationService: AuthenticationService,
    private translate: TranslateService,
    private router: Router) {
    this.navCollapsedMob = false;
    this.headerStyle = '';
    this.menuClass = false;
    this.collapseStyle = 'none';
  }

  // public method
  toggleMobOption() {
    this.menuClass = !this.menuClass;
    this.headerStyle = this.menuClass ? 'none' : '';
    this.collapseStyle = this.menuClass ? 'block' : 'none';
  }

  // this is for eslint rule
  handleKeyDown(event: KeyboardEvent): void {
    if (event.key === 'Escape') {
      this.closeMenu();
    }
  }

  closeMenu() {
    if (document.querySelector('app-navigation.pcoded-navbar').classList.contains('mob-open')) {
      document.querySelector('app-navigation.pcoded-navbar').classList.remove('mob-open');
    }
  }

  ngOnInit() {
    const currentUser = JSON.parse(localStorage.getItem('currentUser')!);
    if (currentUser && currentUser.nameCustomer) {
      this.userName = currentUser.nameCustomer.split(' ')[0];
    }
  }
  useLanguage(language: string) {
    this.translate.use(language);
  }

  logout() {
    this.authenticationService.logout();
  }

  goToSettings() {
    this.router.navigate(['/settings']);
  }
}
